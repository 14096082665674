import * as React from "react"
import { CallToAction } from "../callToAction"
import { headingSplitter, blockToContent } from "../../helpers"
import { getImageUrl } from "../../utils/getImageUrl"

export function FullScreen({ cta, hero }) {
  const headingRef = React.useRef(null)
  const [headingClass, setHeadingClass] = React.useState("text-4xl")
  React.useEffect(() => {
    if (headingRef.current) {
      const headingHeight = headingRef.current.clientHeight
      const newHeadingClass = headingHeight > 85 ? "text-3xl" : "text-4xl"
      setHeadingClass(newHeadingClass)
    }
  }, [hero.heading])

  const bgUrl = hero.bgImage
    ? hero.bgImage
    : getImageUrl(hero.bgimage?.asset?._ref)

  const altText = hero.bgImage ? hero.altText : hero.bgimage?.alttext

  const heroContentPosition =
    hero.headerPosition === "end"
      ? "right"
      : hero.headerPosition === "start"
      ? "left"
      : "center"

  return (
    <>
      <div
        className={`relative ${
          hero.contentPosition === "below" ? "hero" : ""
        } bg-white overflow-hidden`}
      >
        <div className="max-w-screen mx-auto">
          <div
            style={{ paddingTop: `${hero.heroIdentation}` }}
            className="hero_block"
          >
            <div className="relative sm:mt-6 md:mt-8 lg:mt-10 xl:mt-14">
              <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
              <div className="mx-auto">
                <div className="relative sm:shadow-xl sm:overflow-hidden">
                  <div
                    className={`absolute inset-0 hero_bg ${
                      hero.isWavy ? "hero_bg_wavy" : ""
                    }`}
                  >
                    {(hero.bgImage || hero.bgimage?.asset) && (
                      <img
                        src={bgUrl}
                        className="absolute top-0 left-0 w-full h-full object-cover"
                        alt={altText || ""}
                      />
                    )}
                    <div className="absolute inset-0 bg-gray-500/30 mix-blend-multiply" />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1000 100"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
                      ></path>
                    </svg>
                  </div>

                  <div
                    className={`relative px-9 hero_content py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8 elva flex flex-col items_${
                      hero.headerPosition || "center"
                    }`}
                  >
                    <h1
                      ref={headingRef}
                      className={`flex-wrap flex justify-center text_shadow gap-x-3 gap-y-1 ${headingClass} font-extrabold text-white tracking-tight sm:text-5xl lg:text-6xl text-${
                        heroContentPosition || "center"
                      }`}
                    >
                      {headingSplitter(hero?.heading)}
                    </h1>
                    {hero?.subheading && (
                      <p
                        className={`mt-6 max-w-lg text-lg font-bold text_shadow text-white sm:max-w-3xl text-center sm:text-${
                          heroContentPosition || "center"
                        }`}
                      >
                        {hero?.subheading &&
                          blockToContent(hero.subheading[0]?.children)}
                      </p>
                    )}
                    <div className="mt-6 md:mt-10 max-w-sm sm:max-w-none sm:flex hero_button sm:justify-center">
                      <CallToAction {...cta} hero={hero} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FullScreen
