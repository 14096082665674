import * as React from "react"
import { CallToAction } from "../callToAction"

export function Slant({ cta, hero }) {
  return (
    <div
      style={{ paddingTop: `${hero.heroIdentation}` }}
      className="relative overflow-hidden kontakt"
    >
      <main className="mx-auto w-full pt-8 sm:pt-12 md:pt-16 lg:pt-20 md:pb-8">
        <div className="text-center">
          <h1 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl">
            {hero?.heading}
          </h1>
          <p className="mt-3 text-base sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 font-bold">
            {hero?.subheading && hero.subheading[0]?.children[0]?.text}
          </p>
          <CallToAction {...cta} hero={hero} />
        </div>
      </main>
    </div>
  )
}

export default Slant
